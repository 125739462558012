
// pages/collaborations/collaboration/collaboration.js
import {
  mdiCalendarMultipleCheck,
  mdiAccountOutline,
  mdiPencil,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiCogs,
  mdiPlus,
  mdiClose,
  mdiGenderMaleFemale,
  mdiBriefcaseOutline,
  mdiCalendar,
  mdiMapMarker,
  mdiTextSubject,
  mdiAccountCheckOutline,
  mdiCar,
  mdiTranslate,
  mdiDeleteOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';
import * as consts from '@/constants.js';
import DetailComponent from './DetailCollaborationRequestComponent/DetailCollaborationRequestComponent';
import moment from 'moment';

export default {

  name: 'page-collaboration',

  data: () => ({
    icons: {
      mdiCalendarMultipleCheck,
      mdiCalendar,
      mdiAccountOutline,
      mdiPencil,
      mdiEyeOffOutline,
      mdiEyeOutline,
      mdiInformationOutline,
      mdiCogs,
      mdiPlus,
      mdiClose,
      mdiGenderMaleFemale,
      mdiBriefcaseOutline,
      mdiMapMarker,
      mdiTextSubject,
      mdiAccountCheckOutline,
      mdiCar,
      mdiTranslate,
      mdiDeleteOutline,
    },

    fabSettings: false,

  }),

  created() {
    moment.locale('es');
    this.$store.dispatch('collaboration/get', this.$route.params.id);
  },

  computed: {

    isOwnerCollaboration() {
      const profile = this.$store.getters['auth/getProfile'];
      return profile && this.collaboration && this.collaboration.user.id === profile.id;
    },

    ...mapGetters({
      collaboration: 'collaboration/get',
      disableCollaborationRequestStatusLoad: 'collaboration/getDisableCollaborationRequestStatusLoad',
      deleteCollaborationRequestStatusLoad: 'collaboration/getDeleteCollaborationRequestStatusLoad',
      statusLoad: 'collaboration/getStatusLoad',
    }),

    removing() {
      return this.deleteCollaborationRequestStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    changingVisibility() {
      return this.disableCollaborationRequestStatusLoad === consts.REQUEST_HAS_STARTED;
    },

    ageRange() {
      const c = this.collaboration;
      if (c.age_range_lower && !c.age_range_upper) {
        return `Edad mínima: ${c.age_range_lower} años`;
      }

      if (!c.age_range_lower && c.age_range_upper) {
        return `Edad máxima: ${c.age_range_upper} años`;
      }

      if (c.age_range_lower && c.age_range_upper) {
        return `Edad comprendidad ${c.age_range_lower} - ${c.age_range_upper} años`;
      }

      return null;
    },

    listIdioms() {
      return this.collaboration.idioms
        ? (this.collaboration.idioms.reduce((idiomList, idiom) => `${idiomList}, ${idiom.idiom}`, '')).replace(/^,\s/g, '')
        : null;
    },

    dateTimeStart() {
      const c = this.collaboration;
      let dateTime;
      let format;
      if (c.start_time) {
        dateTime = `${c.start_date} ${c.start_time}`;
        format = 'LLL';
      } else {
        dateTime = c.start_date;
        format = 'LL';
      }
      return moment(dateTime).format(format);
    },

    dateEnd() {
      return this.collaboration.end_date
        ? moment(this.collaboration.end_date).format('LL')
        : null;
    },

    createdAt() {
      return moment(this.collaboration.created_at).format('LLL');
    },

  },

  watch: {
    statusLoad(status) {
      if (status === consts.REQUEST_COMPLETE_UNSUCCESSFULLY) {
        this.$router.push({ name: 'Collaborations' });
      }
    },
    deleteCollaborationRequestStatusLoad(status) {
      if (status === consts.REQUEST_COMPLETE_SUCCESSFULLY) {
        this.$store.commit('app/setSnackbarApp', {
          text: `Petición de colaboración eliminada`,
        });
        this.$router.replace({ name: 'Collaborations' });
      }
    },
  },

  methods: {
    disable() {
      this.$store.dispatch('collaboration/disable', {
        id: this.$route.params.id,
        disable: !this.collaboration.disabled,
      });
    },
    remove() {
      this.$store.dispatch('collaboration/delete', {
        id: this.$route.params.id,
      });
    },
  },

  components: {
    DetailComponent,
  },

};
