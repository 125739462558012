/* eslint-disable no-console */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      // Esta actualización se ejecuta cuando se abre una instancia de la pwa
      // Si el mvl del cliente ya tiene la app abierta, aunque esté en otra ventana
      // esto no se va a ejecutar.
      // Para el caso en que ya exista una instancia, cuando se envía cualquier petición api al servidor,
      // se adjunta la versión, si el servidor comprueba de que está desactualizado, enviará un header
      // Que interceptamos.
      window.location.reload(true);
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
