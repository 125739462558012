/*
|-------------------------------------------------------------------------------
| VUEX store.js
|-------------------------------------------------------------------------------
| Builds the data store from all of the modules for the Roast app.
| This is the starting point for all of our Vuex Modules.
| So Vuex is structured by having one module and them submodules.
| This file contains the parent module and it import all of the submodules.
*/

import Vue from 'vue';
import Vuex from 'vuex';

// Imports all modules vuex
import app from '@/modules/app.js';
import auth from '@/modules/auth.js';
import locations from '@/modules/locations.js';
import user from '@/modules/user/index.js';
import { services } from '@/modules/services/index.js';
import { workEquipment } from '@/modules/work_equipment/index.js';
import { associations } from '@/modules/associations/index.js';
import { languages } from '@/modules/languages/index.js';
import { races } from '@/modules/races/index.js';
import { ages } from '@/modules/ages/index';
import users from '@/modules/users/users/index.js';
import filterUsers from '@/modules/users/filter/index.js';
import { requestCollaboration } from '@/modules/collaborations/request/index';
import { collaboration } from '@/modules/collaborations/collaboration/index';
import contactUs from '@/modules/contact_us/index';

/**
 * Initializes Vuex on Vue.
 */
Vue.use(Vuex);

/**
 * Exports data store.
 */
export default new Vuex.Store({

  strict: process.env.NODE_ENV !== 'production',

  modules: {
    app,
    auth,
    locations,
    user,
    services,
    workEquipment,
    associations,
    languages,
    races,
    users,
    filterUsers,
    ages,
    requestCollaboration,
    collaboration,
    contactUs,
  },

});
