/*
|-------------------------------------------------------------------------------
| API modules/app.js
|-------------------------------------------------------------------------------
| The Vuex data store for common app.
*/

export default {
  namespaced: true,

  state: {
    updateAppClient: false,
    snackbar: {
      status: false,
      text: '',
      timeout: 6000,
    },
  },

  mutations: {
    setUpdateAppClient(state, updateAppClient) {
      state.updateAppClient = updateAppClient;
    },

    setSnackbarApp(state, options) {
      state.snackbar = _.assign(state.snackbar, _.pick(options, _.keys(state.snackbar)));
      state.snackbar.status = true;
    },

    resetSnackBarApp(state, options) {
      state.snackbar.status = false;
    },
  },

  getters: {
    getUpdateAppClient(state) {
      return state.updateAppClient;
    },

    getSnackbarApp(state) {
      return state.snackbar;
    },
  },
};
