/*
|-------------------------------------------------------------------------------
| routes.js
|-------------------------------------------------------------------------------
| Contains all of the routes for the application
*/

/**
 * Imports Vue and VueRouter to extend with the routes.
 */
import Vue 			from 'vue';
import VueRouter 	from 'vue-router';
import store 		from './store.js';
import EventBus from './components/EventBusComponent';

/**
 * Extends Vue to use Vue Router.
 */
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes: [
    /**
     * Home
     */
    {
      path: '/',
      name: 'Home',
      component: Vue.component('Home', require('./pages/home/Home.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },

    /**
     * Auth
     */
    {
      path: '/login',
      name: 'AuthLogin',
      component: Vue.component('AuthLogin', require('./pages/auth/login/Login.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/login/verify/:key/:hash/:expiration/:signature',
      name: 'AuthLoginVerification',
      component: Vue.component('AuthLoginVerification', require('./pages/auth/login/Login.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/password/reset/:token',
      name: 'AuthResetPassword',
      component: Vue.component('AuthResetPassword', require('./pages/auth/resetPassword/ResetPassword.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/registro',
      name: 'AuthRegister',
      component: Vue.component('AuthRegister', require('./pages/auth/register/Register.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/approved-user',
      name: 'ApprovedUser',
      component: Vue.component('ApprovedUser', require('./pages/approvedUser/ApprovedUser.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },

    /**
     * Legal
     */
    {
      path: '/terminos-y-condiciones',
      name: 'LegalTerms',
      component: Vue.component('LegalTerms', require('./pages/legal/terms/Terms.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },
    {
      path: '/privacidad',
      name: 'Privacy',
      component: Vue.component('Legal', require('./pages/legal/legal/Legal.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },
    {
      path: '/acerca-de',
      name: 'AboutAs',
      component: Vue.component('AboutAs', require('./pages/legal/aboutAs/AboutAs.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },
    {
      path: '/contacto',
      name: 'ContactUs',
      component: Vue.component('ContactUs', require('./pages/contactUs/ContactUs.vue').default),
      meta: {
        public: true,
        onlyWhenLoggedOut: false,
      },
    },

    /**
     * App
     */
    {
      path: '/profile',
      name: 'OwnProfile',
      redirect: (to) => {
        const loggedUser = store.getters['auth/getProfile'];
        return loggedUser != null && loggedUser.hasOwnProperty('id')
          ? { name: 'Profile', params: { id: loggedUser.id } }
          : { name: 'AuthLogin' };
      },
    },
    {
      path: '/profile/:id',
      name: 'Profile',
      component: Vue.component('Profile', require('./pages/profile/user/User.vue').default),
    },
    {
      path: '/account-settings',
      name: 'Account',
      component: Vue.component('Account', require('./pages/account/Account.vue').default),
    },
    {
      path: '/users',
      name: 'Users',
      component: Vue.component('Users', require('./pages/users/Users.vue').default),
    },
    {
      path: '/collaborations/my-requests',
      name: 'MyCollaborations',
      meta: {
        owner: true,
      },
      component: Vue.component('Collaborations', require('./pages/collaborations/Collaborations.vue').default),
    },
    {
      path: '/collaborations/new-request',
      name: 'CollaborationRequest',
      component: Vue.component('Collaborations', require('./pages/collaborations/adminRequest/AdminRequest.vue').default),
    },
    {
      path: '/collaborations/:filters?',
      name: 'Collaborations',
      meta: {
        owner: false,
      },
      component: Vue.component('Collaborations', require('./pages/collaborations/Collaborations.vue').default),
    },
    {
      path: '/collaboration/:id',
      name: 'Collaboration',
      component: Vue.component('Collaboration', require('./pages/collaborations/collaboration/Collaboration.vue').default),
    },
    {
      path: '/collaboration/:id/edit',
      name: 'CollaborationEdit',
      component: Vue.component('Collaboration', require('./pages/collaborations/adminRequest/AdminRequest.vue').default),
    },
    {
      path: '/help',
      name: 'Help',
      component: Vue.component('Help', require('./pages/help/Help.vue').default),
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Vue.component('Settings', require('./pages/settings/Settings.vue').default),
    },
    {
      path: '*',
      component: Vue.component('NotFound', require('./pages/NotFound.vue').default),
      meta: {
        public: true,
      },
    },

  ],
});

/**
 * @see https://router.vuejs.org/guide/advanced/meta.html
 */
router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const loggedIn = store.getters['auth/getToken'];

  if (!isPublic && !loggedIn) {
    return next({
      name: 'AuthLogin',
      /* query: {
				redirect: to.fullPath //Store url to redirect the user to after login.
			} */
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next({ name: 'OwnProfile' });
  }

  next();
});

export default router;
